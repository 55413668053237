* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.bg-blue {
  background-color: #355469;
}

.bg-light {
  background-color: #ebeff2 !important;
}

.box-verified {
  display: flex;
  width: 100%;
  height: 70vh;
  justify-content: center;
  align-items: center;

  .verified {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;

    svg {
      font-size: 80px;
      margin: 20px;
      color: #dd5b39;
    }
  }

  .verified-spinner {
    width: 80px !important;
    height: 80px;
    border-width: 8px;
    color: #dd5b39;
  }

  .btn-primary {
    background: #355469;
    background-color: #355469 !important;
  }
}

.btn-castom {
  width: 150px;
  height: 40px;
  border-radius: 5px;
  background-color: #355469;
  color: #ffffff;
  font-family: 12px;
  font-weight: 400;
  border: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: transform 0.1s ease;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    transform: translateY(1px);
    opacity: 0.8;
  }

  @media screen and (max-widht: 600px) {
    width: 100%;
  }
}

.footer-logo {
  display: flex;
  justify-content: center;
  padding-top: 10px;

  img {
    width: 100%;
    max-width: 60px;
    height: auto;
  }
}

.footer {
  font-size: 12px;
  color: #355469;
  font-weight: 500;
  padding: 0.8rem;

  p {
    padding: 0;
    margin: 0;
  }
}

.logo {
  width: 100px;
  height: auto;
}
